<!--商品入库-->
<template>
    <div class="mainBox">
        <div class="selectionBar">
            <div class="flex flex-ai-c flex-jc-sb flex-wrap">
                <label>场景：</label>
                <el-input placeholder="搜索商品名称" v-model="select.search" size="small" style="width:300px !important" class="input-with-select">
                    <el-button slot="append" icon="el-icon-search" @click="curr=1;getList()"></el-button>
                </el-input>
				<div></div>
                <!-- <el-button type="primary" block size="small" @click="edit()">新建入库</el-button> -->
            </div>
        </div>

        <!--主要内容-->
        <div class="table" style="height:calc( 100% - 175px );">
            <el-table :data="list"  stripe :header-cell-style="{background:'#fafdff',color:'#606266',fontWeight:'normal'}" @sort-change='getList()' height="calc( 100% - 41px )">
                <el-table-column prop="name" label="商品名称" show-overflow-tooltip></el-table-column>
                <el-table-column label="商品主图" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <el-image class="tableImage" :src="domain + scope.row.image" :preview-src-list="previewList"></el-image>
                    </template>
                </el-table-column>
                <el-table-column prop="sku" label="商品规格" show-overflow-tooltip></el-table-column>
                <el-table-column prop="number" label="入库数" show-overflow-tooltip></el-table-column>
                <el-table-column prop="create_time" label="入库时间" show-overflow-tooltip></el-table-column>
                <el-table-column label="操作" align="center" fixed="right" width="150">
                    <template slot-scope="scope">
                        <el-button type="danger" plain size="mini" @click="deletes(scope.row.id)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <div class="paging">
                <el-pagination @current-change="currentChange" @size-change="sizeChange" :current-page="curr" :page-size="row" background layout="total, sizes, prev, pager, next, jumper" :total="count"></el-pagination>
            </div>
        </div>
    </div>
</template>
<script>
    import {housePlatformListApi,housePlatformDeleteApi}  from '@/api/house.js';
    export default {
        data() {
            return {
                select:{},
                list:[],
                previewList:[],

                title:'新建入库',
                curr: 1, //页码
                row: 10, //每页条数
                pages: 1, //总页数
                count: 0, //总条数
            }
        },
        mounted() {
            this.getList();
        },
        methods: {
            //获取商品入库列表
            getList: function(){
                housePlatformListApi({
                    curr:this.curr,
                    row:this.row
                }).then(response=>{
                    this.list = response.list;
                    this.curr = response.curr;
                    this.pages = response.pages;
                    this.count = response.count;
                    response.list.forEach(item => {
                        this.$set(item,'previewList',[]);
                        this.previewList.push(this.domain + item.image)
                    });
                })
            },
            //新建入库
            edit: function(param = {}){
                if(typeof param.id == 'undefined'){
                    this.title = '新建入库';
                    this.isShow = false;
                }
                //跳转至添加or编辑页面
                this.$router.push({path:'/enterHouseAdd'});
            },

            //删除入库
            deletes:function(id){
                this.$confirm('此操作将永久删除该文件, 是否继续', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }).then(() => {
                    housePlatformDeleteApi({
                        id:id
                    }).then(()=>{
                        this.getList();
                        this.$message({
                            type: 'success',
                            message: '已删除!'
                        });
                    })
                }).catch(()=>{})
            },
            //翻页
            currentChange: function(curr) {
                this.curr = curr;
                this.getList();
            },
            //改变每页条数
            sizeChange: function(row) {
                this.row = row;
                this.getList();
            },
        },
    }
</script>